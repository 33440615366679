'use strict';

var util = require('./util');
var AccessibilityHelper = require('../helper/accessibility-helper');

var currentQuery = null;
var lastQuery = null;
var runningQuery = null;
var delay = 30;
var speed = 250;
var $resultsContainer;
var $searchField;
var $searchSuggestionsCont;
var $popularSearches;

var searchsuggest = {
	/**
	 * @function
	 * @description Configures parameters and required object instances
	 */
	init: function (container, defaultValue) {
		var $searchContainer = $(container);
		var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
		$searchField = $searchForm.find('input[name="q"]');
		$searchSuggestionsCont = $("#search-suggestions-cont");
		$popularSearches = $(".popular-searches");

		// Mobile Search input display
		$(".non-searchbox-search-glass").on("click", function() {
			toggleSearchBox();
			$('#nav-additional-items .search').hide();

			// Auto focus iOS fix
			$('#q').focus();
		});
		$(".non-searchbox-search-glass").on("keypress", function(e) {
			AccessibilityHelper.onSpaceOrEnterAction(e, toggleSearchBox);
		});

		var toggleSearchBox = function() {
			if ($("body").hasClass("search-active-below")) {
				$searchContainer.slideUp(speed, function() {
					$("body").removeClass("search-active-below");
					document.activeElement.blur(); // when focusing out, blur the field so the keyboard hides.
					$('#header .logo').removeClass("logo-search");
				});
			} else {
				$("body").addClass("search-active-below");
				$('#q').focus();
				$searchContainer.slideDown(speed, function() {
					$searchSuggestionsCont.show();
					$($searchForm).show();
					$($popularSearches).show();

					$searchField.val('');
					$searchField[0].focus();
					$('#header .logo').addClass("logo-search");
				});
			}
		};

		// disable browser auto complete
		$searchField.attr('autocomplete', 'off');

		// on focus listener (clear default value)
		$searchField.on('focus', function() {
			if (!$resultsContainer) {
				// create results container if not there. Has size so need to display none to start with.
				$resultsContainer = $('<div/>').attr({
					'id': 'search-suggestions'
				}).appendTo($searchContainer);
			}
			if ($searchField.val() === defaultValue) {
				$searchField.val('');
			}
		});

		// on click of search link, create results container
		$(".searchlink").on("click", function() {
			// create results container if not there. Has size so need to display none to start with.
			util.disableBackgroundScrolling(true);
			$('.background-white-overlay').fadeIn(speed);
			$('#header .logo').addClass("logo-search");
			$searchSuggestionsCont.show();
			$($searchForm).delay(500).queue(function (next) {
				$($searchForm).show();
				$($popularSearches).show();
				next();
				$searchField.focus();
			});
		});


		// disable scrolling if search is open, no matter what the screen size
		if ($searchSuggestionsCont.css('display') == 'block') {
			util.disableBackgroundScrolling(true);
		} else {
			util.disableBackgroundScrolling(false);
			$('.search-suggestion-results').hide();
		}

		// on window resize, the icon is hiding. To fix it, always show the search on resize
		$(window).resize(function() {
			if ($(window).width() > 1000) {
				$('#header .searchlink').show();
			} else {
				$('#header .searchlink').hide();
			}
		});

		// on blur listener - when clicking outside the box.
		$(document).on('click', function (e) {
			// close the window if the target of the click isn't the container or a descendant of the container
			if ($searchContainer.is(":visible")) {
				if (!$searchContainer.is(e.target) && $searchContainer.has(e.target).length === 0) {
					setTimeout(this.clearResults, speed);
					$searchField.val('');
					$($searchForm).hide();
					$($popularSearches).hide();
					$('#search-suggestions').hide();
					$('#header .logo').delay(200).queue(function (next) {
						$('#header .logo').removeClass("logo-search");
						next();
					});
					$($searchSuggestionsCont).delay(800).queue(function (next) {
						$searchSuggestionsCont.hide();
						next();
						$('.background-white-overlay').fadeOut(speed);
					});
				}
			}
		}.bind(this));

		// Close icon
		$searchContainer.on("click", "a.close-sayt-box", function(e) {
			e.preventDefault();
			searchsuggest.clearResults();
			$searchField.val('');
			util.disableBackgroundScrolling(false);
			$($searchForm).hide();
			$('#search-suggestions').hide();
			$($popularSearches).hide();
			$('#header .logo').delay(200).queue(function (next) {
				$('#header .logo').removeClass("logo-search");
				next();
			});
			$($searchSuggestionsCont).delay(800).queue(function (next) {
				$searchSuggestionsCont.hide();
				next();
				$('.background-white-overlay').fadeOut(speed);
			});
		});

		// Search icon submits form (same as enter key)
		$searchContainer.on("click", ".search-box .icon-icon-search", function() {
			$searchForm.submit();

			// Disabling background scrolling only while search popup is open. All styling related to this popup is in
			// cartridges\app_icebreaker\cartridge\scss\components\_quicksearch-popup.scss
			util.disableBackgroundScrolling(true);
		});
		$searchContainer.on("keypress", ".icon-icon-search", function(e) {
			AccessibilityHelper.onSpaceOrEnterAction(e, function() {
				$searchForm.submit();

				// Disabling background scrolling only while search popup is open. All styling related to this popup is in
				// cartridges\app_icebreaker\cartridge\scss\components\_quicksearch-popup.scss
				util.disableBackgroundScrolling(true);
			});
		});

		// on key up listener
		$searchField.keyup(function (e) {

			// get keyCode (window.event is for IE)
			var keyCode = e.keyCode || window.event.keyCode;

			// check for an ENTER or ESC
			if (keyCode === 13 || keyCode === 27) {
				this.clearResults();
				$searchField.val('');
				$($searchForm).hide();
				$($popularSearches).hide();
				$('#search-suggestions').hide();
				$('#header .logo').delay(200).queue(function (next) {
					$('#header .logo').removeClass("logo-search");
					next();
				});
				$($searchSuggestionsCont).delay(800).queue(function (next) {
					$searchSuggestionsCont.hide();
					next();
					$('.background-white-overlay').fadeOut(speed);
				});
				return;
			}

			currentQuery = $searchField.val().trim();

			// no query currently running, init a update
			if (runningQuery === null) {
				runningQuery = currentQuery;
				setTimeout(this.suggest.bind(this), delay);
			}
		}.bind(this));
	},

	/**
	 * @function
	 * @description trigger suggest action
	 */
	suggest: function () {
		// check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
		// (we had a little delay)
		if (runningQuery !== currentQuery) {
			// update running query to the most recent search phrase
			runningQuery = currentQuery;
		}

		// if it's empty clear the results box and return
		if (runningQuery.length === 0) {
			this.clearResults();
			runningQuery = null;
			return;
		}

		// if the current search phrase is the same as for the last suggestion call, just return
		if (lastQuery === runningQuery) {
			runningQuery = null;
			return;
		}

		// build the request url
		var reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', runningQuery);
		reqUrl = util.appendParamToURL(reqUrl, 'legacy', 'false');

		// execute server call
		$.get(reqUrl, function (data) {
			var suggestionHTML = data;
			var ansLength = suggestionHTML.trim().length;

			// if there are results populate the results div
			if (ansLength === 0) {
				this.clearResults();
				util.disableBackgroundScrolling(false);
			} else {
				// update the results div
				$resultsContainer.html(suggestionHTML).fadeIn(speed);
				$('.background-white-overlay').fadeIn(speed);
				// Need to take relative positioning off of "nav-right" so that search suggestion goes full width.
				$(".nav-right").addClass("no-relative");
				$('.no-relative #search-suggestions').hide();

				// add class to be able to animate the height of the main search
				$searchSuggestionsCont.addClass('with-results');
				$($popularSearches).hide();

				// disable scrolling if suggestions are shown, no matter what the screen size
				util.disableBackgroundScrolling(true);

				// Add aria to popup
				this.toggleSearchSuggestExpanded();
			}

			// record the query that has been executed
			lastQuery = runningQuery;
			// reset currently running query
			runningQuery = null;

			// check for another required update (if current search phrase is different from just executed call)
			if (currentQuery !== lastQuery) {
				// ... and execute immediately if search has changed while this server call was in transit
				runningQuery = currentQuery;
				setTimeout(this.suggest.bind(this), delay);
			}
		}.bind(this));
	},

	/**
	 * @function
	 * @description
	 */
	clearResults: function () {
		searchsuggest.toggleSearchSuggestExpanded();

		if (!$resultsContainer) {
			return;
		}
		$resultsContainer.fadeOut(speed, function () {
			$resultsContainer.empty();
			// Need to add relative positioning back to "nav-right" so that customer and store locator drop downs go to the right edge of the inner border.
			$(".nav-right").removeClass("no-relative");

			// remove class to animate height to its original
			$searchSuggestionsCont.removeClass('with-results');
		});

		// After closing the suggestions popup, make sure to enable background scrolling again.
		util.disableBackgroundScrolling(false);
	},

	/**
	 * Checks if the suggested list container has any results.
	 * If there aren't any, set aria-expanded to false.
	 */
	toggleSearchSuggestExpanded: function() {
		if ($resultsContainer) {
			var $suggestions = $resultsContainer.find('.product-suggestions .product-suggestion:visible');
			var numVisibleResults = $suggestions.length;

			// Update aria-expanded based on visibility
			$searchField.attr('aria-expanded', numVisibleResults > 0);

			// Update results status for screen readers
			var resultsText = numVisibleResults;
			$('#results-status').text(resultsText);

			if (numVisibleResults <= 0) {
				$($popularSearches).show();
			}
		}
	}
};

module.exports = searchsuggest;
