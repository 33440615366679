function init() {
	var selector = '#global-email-popup';
	if ($(selector).length) {
		// Build hover exit intent listener
		document.querySelector('body').addEventListener('mouseleave', exitIntentTrigger, false);
	}

	function exitIntentTrigger(event) {
		if ($(selector).length && event.clientY < 0) {
			// Open modal
			var facilemodal = require('./facile-modal');
			facilemodal.showModal(selector, null, 700, null, null, null);

			// trigger EmailShown controller
			var ajax = require('../components/ajax');

			var jsonUrl = $(selector).attr('data-url');
			ajax.getJson({
				url: jsonUrl
			});

			// clear listener
			document.querySelector('body').removeEventListener('mouseleave', exitIntentTrigger, false);
		}
	}
}

module.exports = {
	init: init
};
