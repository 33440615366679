'use strict';

var footer = {
	init: function () {
		var emarsysSignupFooter = '#emarsys-signup-footer-start';
		var emarsysSignup = '#emarsys-signup-main';

		if ($(emarsysSignupFooter).length) {
			footer.emarsysSignupFooter();
		}

		if ($(emarsysSignup).length) {
			footer.emarsysSignup();
		}
	},

	/**
	 * @private
	 * @function emarsysSignupFooter
	 * @description form processing for marketing signup
	 */
	emarsysSignupFooter: function () {
		var $startContainer = jQuery('#emarsys-signup-footer-start');
		var $confirmContainer = jQuery('#emarsys-signup-footer-confirm');

		var $form = jQuery('#emarsys-signup-footer-form');
		var $animate = $form.find('.emarsys-signup-footer-animate');
		var $emailWrap = $form.find('.email-input');
		var $email = $emailWrap.find('input[name="emailAddress"]');
		var $emailError = $emailWrap.find('.field-error');
		var $message = $form.find('.message');
		var $errorMessage = $form.find('.error-message');
		var $checkbox = $form.find('#emarsys-signup-footer-agree');
		var $revealSubscriptionFormBtn = $startContainer.find('.reveal-btn');
		var $submitBtn = $form.find('.submit-btn');

		$checkbox.length && $checkbox.rules('add', {checkboxRequired: true});
		$checkbox.length && $checkbox.on('change', (e) => {
			e.preventDefault();
			updateButtonState();
		});

		// Update the button state on captcha events.
		var reCaptchaStatus;

		// Update the button state on captcha events.
		$form.on('icebreaker:reCaptcha', (event, data) => {
			reCaptchaStatus = data;
			updateButtonState();
		});

		function isCaptchaVerified() {
			return !isCaptchaChallenged() || (reCaptchaStatus && reCaptchaStatus.verified);
		}

		function isCaptchaChallenged() {
			return !!$form.find('.google-recaptcha').length;
		}

		function resetCaptcha() {
			window.googleReCaptcha && window.googleReCaptcha.reset();
			// Update button state by resetting the current reCaptcha status
			if (reCaptchaStatus) {
				reCaptchaStatus.verified = false;
			}
			updateButtonState();
		}

		function isReadyToSubmit() {
			return validateEmail() && (!$checkbox.length || $checkbox.valid()) && isCaptchaVerified();
		}

		function updateButtonState() {
			const enableButton = isReadyToSubmit();
			$submitBtn.toggleClass('disabled', !enableButton);
			$submitBtn.attr('disabled', !enableButton);
		}

		function revealEmailSubscriptionForm() {
			$submitBtn.show();
			$revealSubscriptionFormBtn.hide();
			$emailWrap.removeClass('field-error');
			$emailError.text('');
			$animate.slideDown(300);
			scrollToSection('footer-global');
			$email.focus();
		}

		// Function to handle the form submission
		function handleSubmit() {
			if (isReadyToSubmit() && !$submitBtn.data("clicked")) {
				subscribeEmail();
			}
		}

		// On pressing Enter in the form
		$form.on('keypress', function (e) {
			if (e.which === 13) {
				e.preventDefault(); // Prevent default form submission
				handleSubmit();
			}
		});

		// On clicking the submit button
		$submitBtn.on('click', function (e) {
			e.preventDefault();
			handleSubmit();
		});

		$revealSubscriptionFormBtn.on('click', function (e) {
			e.preventDefault();
			revealEmailSubscriptionForm();
		});

		function scrollToSection(sectionId) {
			const targetSection = document.getElementById(sectionId);

			if (targetSection) {
				targetSection.scrollIntoView({behavior: 'smooth', block: 'start'});
			}
		}

		// On losing focus from the email input
		$email.on('blur', function () {
			updateButtonState();
		});

		// -- Validation Function
		function validateEmail() {
			var $emailVal = $email.val();
			if ($emailVal != "" && $emailVal.length > 0 && (/^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/.test(jQuery.trim($emailVal)))) {
				$emailWrap.removeClass('field-error');
				$emailError.text('');
				$message.html('');
				$errorMessage.html('');
				return true;
			} else {
				$submitBtn.addClass('disabled');
				$emailWrap.addClass('field-error');
				$emailError.text(Resources.VALIDATE_ERRORPREFIX + " " + Resources.VALIDATE_EMAIL);
				return false;
			}
		}

		function getFormData($form) {
			const formData = $form.serializeArray();
			const jsonData = {};
			$.each(formData, function () {
				jsonData[this.name] = this.value == 'on' ? 'true' : this.value;
			});
			return jsonData;
		}

		// -- Subscribe Function
		function subscribeEmail() {
			if (isReadyToSubmit()) {
				// Lock join button to prevent multiple clicks
				$submitBtn.data("clicked", true);

				// Loading
				$submitBtn.addClass("loading");

				$message.html('');
				$errorMessage.html('');

				setTimeout(function () {
					$email.blur();
				}, 100);
				var data = getFormData($form);
				jQuery.ajax({
					type: "POST",
					url: EmarsysUrls.footerSubscription,
					data: data,

					success: function (response) {
						if (response && response.success) {
							$confirmContainer.html(response.message);
							$startContainer.slideUp(300);
							$confirmContainer.slideDown(300);

							dataLayer.push({'event': 'newsletterSignupFooter', 'successOrFailure': 'success'});
							// Unload exit intent
							$('#global-email-popup').remove();
						} else {
							resetCaptcha();
							if (response.error == true) {
								$errorMessage.html(Resources.VALIDATE_ERRORPREFIX + " " + response.message);
							} else {
								$message.html(response.message);
							}
							dataLayer.push({'event': 'newsletterSignupFooter', 'successOrFailure': 'failure'});
						}
						$submitBtn.removeClass("loading");
						$submitBtn.data("clicked", false);
					},
					error: function () {
						resetCaptcha();
						$message.html(Resources.SIGNUP_ERROR);
						dataLayer.push({'event': 'newsletterSignupFooter', 'successOrFailure': 'failure'});
						$submitBtn.removeClass("loading");
						$submitBtn.data("clicked", false);
					}
				});
			}
		}
	},

	/**
	 * @private
	 * @function emarsysSignup
	 * @description form processing for marketing signup
	 */
	emarsysSignup: function () {
		var $emarsysSignup = jQuery('#emarsys-signup-main');
		var $subSource = jQuery('#email-signup-wrapper .subsource');

		var $form = jQuery('#emarsys-signup-form');
		var $firstName = $form.find('input[name="firstname"]');
		var $message = $form.find('.message');
		var $checkbox = $form.find('#emarsys-signup-agree');
		var $submitBtn = $form.find('.submit-btn');
		var $emailWrap = $form.find('.email-input');
		var $email = $emailWrap.find('input');
		var $emailError = $emailWrap.find('.invalid-field-error');

		$form.validate();

		$checkbox.length && $checkbox.rules('add', {checkboxRequired: true});

		// -- Actions
		$form.on('keypress', function (e) {
			if (e.which == 13) {
				subscribeEmail(this);
			}
		});

		$submitBtn.on('click', function (e) {
			e.preventDefault();

			if (!$submitBtn.data("clicked")) {
				subscribeEmail(this);
			}

		});

		$email.on('blur', function () {
			if ($email.val() != "") {
				validateEmail();
			} else {
				$emailError.text('');
			}
		});

		// -- Validation Function
		function validateEmail() {
			var $emailVal = $email.val();
			if ($emailVal.length > 0 && (/^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/.test(jQuery.trim($emailVal)))) {
				$emailWrap.removeClass('field-error');
				$emailError.text('');
				$message.html('');
				return true;
			} else {
				$emailWrap.addClass('field-error');
				$emailError.text(Resources.VALIDATE_EMAIL);
				return false;
			}
		}

		// -- Subscribe Function
		function subscribeEmail() {
			var $emailVal = $email.val();
			if ($form.valid() && validateEmail()) {
				// Lock join button to prevent multiple clicks
				$submitBtn.data("clicked", true);

				// Loading
				$submitBtn.addClass("loading");

				jQuery.ajax({
					type: "POST",
					url: EmarsysUrls.emarsysSignup,
					data: {firstName: $firstName.val(), emailAddress: $emailVal, formatajax: true, subSource: $subSource.val()},

					success: function (response) {
						$submitBtn.removeClass("loading");
						// release the join button
						$submitBtn.data("clicked", false);

						if (response && response.optedInError) {
							$message.html('').html(response.message);
						} else {
							$emarsysSignup.html('').html(response);
						}

					},
					error: function () {
						$message.html('').html(Resources.SIGNUP_ERROR);
						$submitBtn.removeClass("loading");

						// release the join button
						$submitBtn.data("clicked", false);
					}
				});
			}
		}
	}
};

module.exports = footer;
